<template>
  <div class="border-end bg-white" id="sidebar-wrapper">
    <div class="sidebar-heading border-bottom bg-light">Emails</div>
    <div class="list-group list-group-flush ft-small">
      <template v-if="emailList.length">
        <div
          class="list-group-item list-group-item-action list-group-item-light p-3 clicable no-select"
          v-for="item in emailList"
          :key="item"
          :class="{ active: item == email }"
          @click.stop.prevent="email = email == item ? '' : item"
        >
          <div class="app-account">{{ item.split("@")[0] }}</div>
          <div class="app-domain">@{{ item.split("@")[1] }}</div>
        </div>
      </template>
      <template v-else>
        <div
          class="list-group-item list-group-item-action list-group-item-light p-3 clicable no-select"
        >
          No emails
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    emailList() {
      return this?.$store?.getters?.emailList || [];
    },
    email: {
      set(value) {
        this.$store.dispatch("setActiveEmail", value);
      },
      get() {
        return this.$store.getters.activeEmail;
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-group {
  max-height: calc(100dvh - 67px);
  overflow: auto;
}

.ft-small {
  font-size: 0.8rem !important;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #49569f;
  border-color: #636464;
}

.app-account {
  display: inline-block;
  white-space: nowrap;
}

.app-domain {
  display: inline-block;
  white-space: nowrap;
  opacity: 0.6;
}
</style>
