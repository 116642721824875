<template>
  <div id="page-content-wrapper">
    <nav class="navbar navbar-expand-md bg-light">
      <div class="container-fluid">
        <span class="btn btn-outline-default" id="sidebarToggle">
          <span class="material-icons">menu</span>
        </span>
        <span>
          <span class="clicable" @click.stop.prevent="editEmail">
            {{ email || "select an email" }}
            <span
              class="material-icons"
              style="font-size: 1.2rem; margin: 0 20px"
            >
              edit
            </span>
          </span>
          <span class="clicable" @click.stop.prevent="copy">
            <span class="material-icons" style="font-size: 1.2rem">
              content_copy
            </span>
          </span>
        </span>
        <span
          class="btn btn-outline-default"
          @click="
            email = '';
            editEmail();
          "
        >
          <span class="material-icons">person_add</span>
        </span>
      </div>
    </nav>
    <div class="container-fluid">
      <div class="text-center" v-if="busy">
        <div
          class="spinner-border"
          style="width: 4rem; height: 4rem; color: #999; margin: 5rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="h5 text-secondary" v-if="!email">No email selected</div>
        <Message
          v-for="(item, ix) in items"
          :key="ix"
          :message="item"
          style="margin-bottom: 30px"
        />
      </div>
    </div>
    <div class="modal" tabindex="-1" id="myModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              v-if="this.formData.email && this.formData.email == email"
            >
              Remove email
            </h5>
            <h5 class="modal-title" v-else>New AppInbox Account</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.stop.prevent="addEmail">
              <div class="mb-3">
                <label for="myInput" class="col-form-label">Email:</label>
                <input
                  type="text"
                  class="form-control"
                  :value="formData.email"
                  disabled
                  v-if="this.formData.email && this.formData.email == email"
                />
                <div v-else class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    v-model="formData.email"
                    id="myInput"
                    :disabled="
                      this.formData.email && this.formData.email == email
                        ? true
                        : false
                    "
                  />
                  <span class="input-group-text" id="basic-addon2"
                    >@{{ domain }}
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              v-if="this.formData.email && this.formData.email == email"
              type="button"
              class="btn btn-secondary"
              @click.stop.prevent="delEmail"
            >
              Remove
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              @click.stop.prevent="addEmail"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="liveToast"
      class="toast align-items-center bg-warning border-0 position-fixed bottom-0 end-0 p-1"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body">
          {{ email }}
          <small style="color: red"> copied!</small>
        </div>
        <button
          type="button"
          class="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "./Message.vue";
export default {
  name: "PageContent",
  components: {
    Message,
  },
  data: () => ({
    busy: false,
    domain: "app-inbox.appspotmail.com",
    formData: {
      email: "",
    },
    items: [],
  }),
  computed: {
    email: {
      set(value) {
        this.$store.dispatch("setActiveEmail", value);
      },
      get() {
        return this.$store.getters.activeEmail;
      },
    },
  },
  watch: {
    email(n) {
      if (n) {
        this.busy = true;
        this.fetchMessages().then((items) => {
          this.busy = false;
          this.$set(this, "items", items);
        });
      } else {
        this.$set(this, "items", []);
      }
    },
  },
  methods: {
    fetchMessages() {
      // //curl -d "email=m14@app-inbox.appspotmail.com" https://app-inbox.appspot.com
      return new Promise((resolve, reject) => {
        var myInit = {
          method: "POST",
        };
        fetch(
          "https://app-inbox.appspot.com?" +
            "email=" +
            encodeURIComponent(this.email) +
            "&limit=10",
          myInit
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            if (data) {
              resolve(data);
            } else {
              reject(null);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editEmail() {
      if (this.email) {
        this.formData.email = this.email;
        this.myModal.show();
      } else {
        this.formData.email = "";
        this.myModal.show();
      }
    },
    addEmail() {
      if (!this.formData.email) return;
      let lst = this.formData.email.split("@")[0].split(",");
      let email = "";
      lst.forEach((item) => {
        email = `${item}@${this.domain}`;
        this.$store.dispatch("addEmail", email);
      });
      this.email = email;
      this.formData.email = "";
      this.myModal.hide();
    },
    delEmail() {
      if (!this.formData.email) return;
      this.$store.dispatch("delEmail", this.formData.email);
      this.formData.email = "";
      this.email = "";
      this.myModal.hide();
    },
    copy() {
      this.$utils.toClipboard(this.email);
      const toast = new window.bootstrap.Toast(
        document.getElementById("liveToast")
      );
      toast.show();
    },
  },
  mounted() {
    const $el = document.getElementById("myModal");
    $el.addEventListener("shown.bs.modal", function () {
      var myInput = document.getElementById("myInput");
      if (myInput && myInput.focus) {
        myInput.focus();
      }
    });
    this.myModal = new window.bootstrap.Modal($el);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
