import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    emailList:[],
    activeEmail:""
  },
  mutations: {
    SET_EMAIL_LIST(state, value){
      Vue.set(state, "emailList", value)
    },
    SET_ACTIVE_EMAIL(state,value){
      state.activeEmail=value
    }
  },
  actions: {
    init(context){
      context.commit("SET_EMAIL_LIST",JSON.parse(
        localStorage.getItem("emailList")||"[]"
      ))
    },
    addEmail(context, email){
      if ((context.state.emailList||[]).indexOf(email)!=-1) return;
      let lst = JSON.parse(JSON.stringify(context.state.emailList||[]));
      lst.push(email);
      localStorage.setItem("emailList", JSON.stringify(lst));
      context.commit("SET_EMAIL_LIST",lst)
    },
    delEmail(context, email){
      if ((context.state.emailList||[]).indexOf(email)==-1) return;
      let lst = JSON.parse(JSON.stringify((context.state.emailList||[]).filter((i)=>i!=email)));
      localStorage.setItem("emailList", JSON.stringify(lst));
      context.commit("SET_EMAIL_LIST",lst)
    },
    setActiveEmail(context, value){
      context.commit("SET_ACTIVE_EMAIL",value)
    }
  },
  getters:{
    emailList(state){
      return state.emailList;
    },
    activeEmail(state){
      return state.activeEmail;
    }
  },
  modules: {},
});
