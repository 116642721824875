<template>
    <div class="d-flex" id="wrapper">
        <SideBar/>    
        <PageContent/>
    </div>
</template>

<script>
import SideBar from "./SideBar.vue"
import PageContent from "./PageContent.vue"
export default {
  name: "Dashboard",
  components:{
    SideBar,
    PageContent
  },
  mounted(){
    this.$store.dispatch("init")
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
