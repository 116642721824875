<template>
  <div class="card card-border-color" v-if="uuid">
    <div
      class="card-header clicable no-select collapsed"
      data-bs-toggle="collapse"
      :data-bs-target="`#${uuid}`"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      <div class="float-start">
        {{ message.created }} - {{ message.subject }}
      </div>
      <div class="float-end">
        <span class="material-icons card-toggle" style="font-size: 1.2rem">
          expand_more
        </span>
      </div>
    </div>
    <div class="collapse" :id="uuid">
      <div class="card card-body">
        <h5 class="card-title text-secondary">
          from: {{ message.email_from }}
        </h5>
        <p class="card-text" v-html="message.body"></p>
      </div>
    </div>
    <!-- <div class="card-body">
      <h5 class="card-title text-primary">from: {{message.email_from}}</h5>
      <p class="card-text" v-html="message.body"></p>
    </div> -->
  </div>
</template>

<script>
let uuid = 0;
export default {
  name: "Message",
  props: {
    message: {
      type: Object,
      required: false,
      default: () => ({
        body: "",
        created: "",
        email_from: "",
        email_to: "",
        id: "",
        subject: "",
        updated: "",
      }),
    },
  },
  beforeCreate() {
    this.uuid = btoa(`${this.$options.name}${uuid}`).replace(/=/g, "");
    uuid += 1;
  },
};
</script>

<style scoped>
.card-border-color {
  border-color: #b7b7b7 !important;
}

.card-toggle {
  transform: rotate(0deg);
}

.card-header.collapsed > div.float-end > .card-toggle {
  transform: rotate(270deg);
}
</style>
